import { Splide } from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import AOS from "aos";
import videojs from "video.js";

(function ($) {
  AOS.init();
  //Case Study section
  $(document).ready(function () {
    // Hide all sections initially, except the first one
    $(".section-toggle").hide().first().show();

    // Set the first button in each section to active state (btn-active)
    $(".section-toggle .cs-btn-container button:first-child").addClass("btn-active");

    // Handle button clicks
    $(".section-toggle .cs-btn-container button").click(function () {
      var sectionName = $(this).data("section");

      // Clear btn-active from all buttons on the page (ensures complete removal)
      $(".cs-btn-container button").removeClass("btn-active");

      // Find all matching buttons across the page (regardless of section)
      var matchingButtons = $(`.cs-btn-container button[data-section="${sectionName}"]`);

      // Add btn-active to all matching buttons
      matchingButtons.addClass("btn-active");

      // Toggle the section based on clicked button's data-section
      $(".section-toggle").hide();
      var sectionToShow = $(".section-toggle[data-section='" + sectionName + "']");
      sectionToShow.show();

      // Scroll to the top of #case-studies section
      $("html, body").animate(
        {
          scrollTop: $("#case-studies").offset().top
        },
        "fast"
      );
    });
  });

  // MCID UTM parameter

  $(document).ready(function () {
    // Function to get URL parameters
    function getUrlParameter(name) {
      var regex = new RegExp("[?&]" + name + "=([^&#]*)");
      var results = regex.exec(window.location.search);
      return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    // Check if "mcid" parameter exists in the URL
    var mcidParam = getUrlParameter("mcid");
    if (mcidParam) {
      // Store the mcid value in localStorage
      localStorage.setItem("mcid", mcidParam);

      // Optionally, you could set an expiration timestamp, but this requires additional logic to handle expiry
      // localStorage.setItem('mcidExpiry', new Date().getTime() + 24 * 60 * 60 * 1000); // 24 hours expiry
    }

    // Retrieve the mcid value from localStorage
    var mcidStorage = localStorage.getItem("mcid");
    if (mcidStorage) {
      // If the input elements with class "mcidInput" exist, replace their values with the mcid localStorage value
      $(".mcidInput").val(mcidStorage);
    }

    // Optionally, handle expiry if you set an expiry timestamp
    // var mcidExpiry = localStorage.getItem('mcidExpiry');
    // if (mcidExpiry && new Date().getTime() > mcidExpiry) {
    //     localStorage.removeItem('mcid');
    //     localStorage.removeItem('mcidExpiry');
    // }
  });

  // Trustpilot Carousel

  // Remove this and uncomment all below when TP reviews are in
  $(document).ready(function () {
    if ($("#reviewsCarousel").length > 0) {
      new Splide("#reviewsCarousel", {
        perPage: 4,
        type: "loop",
        gap: 40,
        autoplay: true,
        interval: 5000,
        arrows: false,
        perMove: 1,
        padding: {
          left: 0,
          right: 120
        },
        breakpoints: {
          1700: {
            perPage: 3
          },
          1300: {
            perPage: 2,
            gap: 24
          },
          800: {
            perPage: 1,
            padding: 24
          }
        }
      }).mount();
    }
  });

  $(document).ready(function () {
    // Function to fetch latest Trustpilot reviews
    async function fetchTrustpilotReviews() {
      const apiUrl =
        "https://api.trustpilot.com/v1/business-units/5d550809e2c1ea0001add4ca/reviews?apikey=MAyltkXboqm7X4xkLTcVndqcZTvZ4PUd&stars=5";
      const count = 50; // Number of reviews to fetch
      const maxCharacters = 270; // Maximum characters allowed for a review

      try {
        // Check if the reviews are already cached and not expired
        const cachedReviews = localStorage.getItem("trustpilotReviews");
        const cacheExpiration = localStorage.getItem("trustpilotCacheExpiration");
        const currentTime = new Date().getTime();

        if (cachedReviews && cacheExpiration && currentTime < cacheExpiration) {
          const data = JSON.parse(cachedReviews);
          return data.reviews.filter(review => review.text.length > 100 && review.text.length <= maxCharacters);
        }

        const response = await fetch(`${apiUrl}&perPage=${count}`);

        if (!response.ok) {
          throw new Error("Failed to fetch reviews");
        }

        const data = await response.json();
        const filteredReviews = data.reviews.filter(
          review => review.text.length > 100 && review.text.length <= maxCharacters
        );

        // Cache the fetched reviews for 24 hours
        localStorage.setItem("trustpilotReviews", JSON.stringify(data));
        const expirationTime = currentTime + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
        localStorage.setItem("trustpilotCacheExpiration", expirationTime);

        return filteredReviews;
      } catch (error) {
        console.error("Error fetching Trustpilot reviews:", error);
        return [];
      }
    }

    // Function to format date in desired format: "19 May 2023"
    function formatDate(dateString) {
      const options = {
        day: "numeric",
        month: "long",
        year: "numeric"
      };
      return new Date(dateString).toLocaleDateString(undefined, options);
    }

    async function createReviewsCarousel() {
      const reviews = await fetchTrustpilotReviews();

      const carouselContainer = document.getElementById("reviewsCarousel");
      const carouselList = carouselContainer.querySelector(".splide__list");

      const carouselItems = reviews.map(review => {
        const formattedDate = formatDate(review.createdAt);
        const truncatedText = review.text.length > 270 ? review.text.slice(0, 270) + "..." : review.text;
        const reviewerName = review.consumer.displayName; // Access reviewerName property instead of displayName

        // Customize how you want to display each review item
        return `<li class="splide__slide">
                    <div class="review-container">
                        <div class="review-header mb-4">
                            <img src="/wp-content/themes/understrap-main/img/trustpilot-stars.svg" alt="Trustpilot 5 Stars" width="105">
                        </div>
                        <div class="review-body d-flex justify-content-between flex-column">
                        <p class="review-text">${truncatedText}</p>
                        <div class="review-footer d-flex justify-content-between">
                            <p class="reviewer-name">${reviewerName}</p> <!-- Display reviewerName -->
                            <p class="review-date">${formattedDate}</p>
                        </div>
                        </div>
                    </div>
                </li>`;
      });

      carouselList.innerHTML = carouselItems.join("");
      // Initialize Splide carousel
      new Splide("#reviewsCarousel", {
        perPage: 4,
        type: "loop",
        gap: 40,
        autoplay: true,
        interval: 5000,
        arrows: false,
        perMove: 1,
        padding: {
          left: 0,
          right: 120
        },
        breakpoints: {
          1700: {
            perPage: 3
          },
          1300: {
            perPage: 2,
            gap: 24
          },
          800: {
            perPage: 1,
            padding: 24
          }
        }
      }).mount();
    }

    async function displayReviewCount() {
      try {
        const reviewCountResponse = await fetch(
          "https://api.trustpilot.com/v1/business-units/5d550809e2c1ea0001add4ca?apikey=MAyltkXboqm7X4xkLTcVndqcZTvZ4PUd"
        );
        const reviewCountData = await reviewCountResponse.json();
        const reviewCount = reviewCountData.numberOfReviews.total;

        const reviewCountText = `Rated Excellent with ${reviewCount} reviews`;
        $(".trustpilot-rating-api").text(reviewCountText);
      } catch (error) {
        console.error("Error fetching Trustpilot review count:", error);
        // $('.trustpilot-rating-api').text('Failed to retrieve review count');
      }
    }

    // Call the function to create the carousel
    createReviewsCarousel();

    // Call the function to display the review count
    displayReviewCount();
  });
  $(document).ready(function () {
    // Check if '.splide-guides' elements exist on the page
    if ($(".splide-hero-home").length > 0) {
      var video = $("#video-carousel");
      var elms = document.getElementsByClassName("splide-hero-home");
      for (var i = 0; i < elms.length; i++) {
        new Splide(elms[i], {
          type: "loop",
          gap: 24,
          arrows: false,
          pagination: false,
          autoplay: true,
          wheel: true,
          // isNavigation: true,
          padding: {
            left: 0,
            right: 120
          },
          fixedWidth: "350px",
          breakpoints: {
            2000: {
              fixedWidth: "325px"
            },
            1400: {
              fixedWidth: "250px",
              gap: 12
            },

            991: {
              padding: {
                left: 100,
                right: 100
              }
            },
            767: {
              fixedWidth: "225px",
              padding: {
                left: 50,
                right: 50
              }
            }
          }
        }).mount();
      }
      video.on("play", function () {
        splide.pause();
      });
    }
  });
  $(document).ready(function () {
    // Check if '.splide-logos' elements exist on the page
    if ($(".splide-logos").length > 0) {
      var elms = document.getElementsByClassName("splide-logos");
      for (var i = 0; i < elms.length; i++) {
        new Splide(elms[i], {
          type: "loop",
          perPage: 5,
          arrows: false,
          pagination: false,
          pauseOnHover: false,
          pauseOnFocus: false,

          autoScroll: {
            speed: 0.8,
            autoStart: true,
            pauseOnFocus: false,
            pauseOnHover: false
          },
          breakpoints: {
            1000: {
              perPage: 5,
              speed: 0.6
            },
            800: {
              perPage: 4
            },
            500: {
              perPage: 3
            }
          }
        }).mount({
          AutoScroll
        });
      }
    }
  });
  $(document).ready(function () {
    // Check if '.splide-logos' elements exist on the page
    if ($(".splide-logos-product").length > 0) {
      var elms = document.getElementsByClassName("splide-logos-product");
      for (var i = 0; i < elms.length; i++) {
        new Splide(elms[i], {
          type: "loop",
          perPage: 4,
          arrows: false,
          pagination: false,
          pauseOnHover: false,
          pauseOnFocus: false,

          autoScroll: {
            speed: 0.75,
            autoStart: true,
            pauseOnFocus: false,
            pauseOnHover: false
          },
          breakpoints: {
            1000: {
              perPage: 3
            }
          }
        }).mount({
          AutoScroll
        });
      }
    }
  });
})(jQuery);

// Mega Nav

// (c) 2020-2023 Written by Simon Köhler in Panama
// simonkoehler.com

document.addEventListener("DOMContentLoaded", function (event) {
  // Hamburger menu
  var navbarToggler = document.querySelectorAll(".navbar-toggler")[0];
  var hamburgerIcon = navbarToggler.querySelector(".hamburger");

  navbarToggler.addEventListener("click", function (e) {
    // Toggle active class for hamburger icon
    hamburgerIcon.classList.toggle("active");
  });

  // Select the <html> element
  var html = document.querySelectorAll("html")[0];

  // Select the first element with the attribute 'data-bs-toggle-theme'
  var themeToggle = document.querySelectorAll("*[data-bs-toggle-theme]")[0];

  // Set the default theme to 'dark' for the <html> element
  html.setAttribute("data-bs-theme", "dark");

  // Check if a themeToggle element is found
  if (themeToggle) {
    // Add a click event listener to the themeToggle element
    themeToggle.addEventListener("click", function (event) {
      // Prevent the default behavior of the click event
      event.preventDefault();

      // Check the current theme attribute value of the <html> element
      if (html.getAttribute("data-bs-theme") === "dark") {
        // If the current theme is 'dark', change it to 'light'
        html.setAttribute("data-bs-theme", "light");
      } else {
        // If the current theme is not 'dark', change it back to 'dark'
        html.setAttribute("data-bs-theme", "dark");
      }
    });
  }

  // Close navbar when clicking outside of it
  document.body.addEventListener("click", function (e) {
    var navbar = document.querySelector(".navbar-collapse");
    var target = e.target;

    // Check if the clicked element is not within the navbar or the navbar toggler
    if (!navbar.contains(target) && !navbarToggler.contains(target)) {
      // Check if navbar is open
      if (navbar.classList.contains("show")) {
        // Close the navbar
        navbar.classList.remove("show");
        hamburgerIcon.classList.remove("active");
      }
    }
  });
});

jQuery(document).ready(function ($) {
  // Check if there's a hash in the URL
  var hash = window.location.hash;

  // If the hash is instant-callback or book-a-call, show the corresponding section
  if (hash === "#instant-callback" || hash === "#instant-callback/") {
    $("#selector").hide();
    $("#instant-callback").fadeIn();
    $(".back-btn-home").hide();
    $(".back-btn").show();
    $("#business-protection-review-calendly").hide();
  } else if (hash === "#book-a-call" || hash === "#book-a-call/") {
    $("#selector").hide();
    $("#book-a-call").fadeIn();
    $(".back-btn-home").hide();
    $(".back-btn").show();
    $("#business-protection-review-calendly").hide();
  } else if (hash === "#online" || hash === "#online/") {
    $("#selector").hide();
    $("#online-first").fadeIn();
    $(".back-btn-home").hide();
    $(".back-btn").show();
    $("#business-protection-review-calendly").hide();
  } else if (hash === "#business" || hash === "#business/") {
    $("#online-btn").addClass("d-none");
    $("#protection-review-calendly").hide();
    $("#business-protection-review-calendly").removeClass("d-none");
    // Select all elements with the id 'notes'
    $('input[id="notes"]').each(function () {
      // Get the current value of the field
      var currentValue = $(this).val();
      // Prepend the text "Business " to the current value
      $(this).val("Business " + currentValue);
    });
  } else if (hash === "#pmi" || hash === "#pmi/") {
    $("#online-btn").addClass("d-none");
    $("#protection-review-calendly").hide();
    $("#pmi-calendly").removeClass("d-none");
    // Select all elements with the id 'notes'
    $('input[id="notes"]').each(function () {
      // Get the current value of the field
      var currentValue = $(this).val();
      // Prepend the text "Business " to the current value
      $(this).val("PMI " + currentValue);
    });
  }

  // When a selector-btn is clicked
  $(".selector-btn").click(function () {
    // Get the data-method attribute value
    var method = $(this).data("method");

    // Hide the selector

    // Show the corresponding section based on the data-method value
    if (method === "online") {
      // var url = "https://help.coverdirect.com/chatbot/passthru/34621d31cabf215eabf30314fdf6cd59";
      // $(location).attr("href", url);
      $("#selector").hide();
      $("#online-first").fadeIn();
      $("#book-a-call").hide();
      $(".back-btn-home").hide();
      $(".back-btn").show();
    } else if (method === "instant-callback") {
      $("#selector").hide();
      $("#instant-callback").fadeIn();
      $("#book-a-call").hide();
      $(".back-btn-home").hide();
      $(".back-btn").show();
    } else if (method === "book-callback") {
      $("#selector").hide();
      $("#instant-callback").hide();
      $("#book-a-call").fadeIn();
      $(".back-btn-home").hide();
      $(".back-btn").show();
    }
  });

  // When back-btn is clicked (using event delegation)
  $(document).on("click", ".back-btn", function (event) {
    // Show the selector
    $("#selector").fadeIn();
    $("#instant-callback, #book-a-call, #online-first").hide();
    $(".back-btn").hide();
    $(".back-btn-home").show();
  });

  // When back-btn-home is clicked
  $(".back-btn-home").click(function () {
    if (document.referrer && document.referrer !== window.location.href) {
      // If there is a previous page, navigate back to it
      window.history.back();
    } else {
      // If there isn't a previous page, go to the home page
      window.location.href = "/";
    }
  });
});
