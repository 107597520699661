(function ($) {
  $(document).ready(function () {
    // When any of the dropdowns change
    $("select").change(function () {
      // Get the selected day, month, and year values
      var selectedDay = $("#day").val();
      var selectedMonth = $("#month").val();
      var selectedYear = $("#year").val();

      // Check if all values are selected
      if (selectedDay !== "Day" && selectedMonth !== "Month" && selectedYear !== "Year") {
        // Combine them into a single DOB string
        var fullDob = selectedDay + "-" + selectedMonth + "-" + selectedYear;

        // Update the hidden input field with the full DOB
        $("#dob").val(fullDob);
      }
    });
  });

  $(document).ready(function () {
    // Function to handle radio button changes
    $('input[name="quotesfor"]').change(function () {
      if ($(this).val() === "me-and-partner") {
        // Show the #partner-row when "Me & My Partner" is selected and set display to flex
        $("#partner-row").css("display", "flex");
        $("#partnerfirstname, #partnerlastname").prop("disabled", false); // Enable the inputs
      } else {
        // Hide the #partner-row for other selections
        $("#partner-row").hide();
        $("#partnerfirstname, #partnerlastname").prop("disabled", true); // Disable the inputs
      }
    });
  });

  // Function to scroll to the next question with a 80px offset
  function scrollToNextQuestion() {
    var $currentQuestion = $(".form-question-row:visible");
    var $nextQuestion = $currentQuestion.next(".form-question-row");
    if ($nextQuestion.length > 0) {
      $("html, body").animate(
        {
          scrollTop: $nextQuestion.offset().top - 80 // Offset by 80 pixels
        },
        100
      );
    }
  }

  // Attach a handler to the change event of the "quotesfor" radio buttons
  $('input[name="quotesfor"]').change(function () {
    if ($(this).valid()) {
      scrollToNextQuestion();
    }
  });

  // Initialize the jQuery Validate plugin on your form, focusing only on the "quotesfor" field
  $(document).ready(function () {
    $("#quote").validate({
      // Specify validation rules for the "quotesfor" field only
      rules: {
        quotesfor: "required"
      },
      // Specify error messages for the "quotesfor" field only
      messages: {
        quotesfor: "Please select an option"
      },
      submitHandler: function (form) {
        // Handle form submission here
        // You can use AJAX to submit the form data if needed
      }
    });
  });

  // $(document).ready(function () {
  //   // Function to get URL parameters
  //   function getUrlParameter(name) {
  //     name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  //     var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  //     var results = regex.exec(location.search);
  //     return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  //   }

  //   // Check the "results" parameter
  //   var resultsValue = getUrlParameter("results");

  //   // Check if the value is "false"
  //   if (resultsValue === "false") {
  //     // Set the value as a local storage item
  //     localStorage.setItem("showResults", "false");
  //   }
  // });
})(jQuery);
